//.products-grid {
//  .item {
//    &-inner {
//      &:hover {
//        .product {
//          &-item {
//            &-container {
//              .product-item-photo {
//                &:not(.do-hover) {
//                  .product-image-wrapper {
//                    img {
//                      -webkit-box-shadow: rgba(0, 0, 0, 0.05) 1px 2px 3px 0px;
//                      box-shadow: rgba(0, 0, 0, 0.05) 1px 2px 3px 0px;
//                    }
//                  }
//                }
//              }
//            }
//          }
//        }
//      }
//    }
//  }
//}

.main {
  .products-grid,
  #dffullscreen {
    .item {
      &-inner {
        .product {
          &-item {
            &-container {
              .product-image-wrapper {
                img {
                  box-shadow: 7px 7px 15px 0 rgba(255, 255, 255, 0.1);
                  -webkit-box-shadow: 7px 7px 15px 0 rgba(255, 255, 255, 0.1);
                  transition: 0.5s ease-out;
                  -moz-transition: 0.5s ease-out;
                  -webkit-transition:0.5s ease-out;
                  -o-transition: 0.5s ease-out;
                }
              }
            }
          }
        }
      }
    }
  }
}