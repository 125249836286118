.module-footer {
  > .module {
    &-inner {
      .copyright {
        &__content {
          .list-inline {
            &-item {
              a{
                color: $color-dark-alt;
                &:hover{
                  color: $color-brown;
                }
              }
            }
          }
        }
      }
      .social-networks {
        color: $color-white;
        .fa {
          color: $color-white;
        }
      }
    }
  }
}
